<template>
  <a-modal
    title="处理上报"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="dealStatusApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="标题"
      >
        {{ queryParam.title }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网格员"
      >
        {{ queryParam.realName }}({{ queryParam.telephone }})
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="状态"
        :validateStatus="checkParams.missionStatusStatus"
        :help="checkParams.missionStatusStatusMsg"
      >
        <a-select
          style="width:170px"
          v-model="queryParam.status"
          placeholder="请选择状态"
          @change="changeStatus"
        >
          <a-select-option :key="0" :value="0">待审核</a-select-option>
          <a-select-option :key="1" :value="1">已处理</a-select-option>
          <a-select-option :key="2" :value="2">暂缓处理</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-textarea
          style="width: 90%;"
          :rows="3"
          v-model="queryParam.content"
          placeholder="备注"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="图片"
      >
        <a-upload
          :fileList="fileList"
          listType="picture-card"
          :disabled="isImageUpload"
          :beforeUpload="beforeUpload"
          :customRequest="customRequest"
          @preview="handlePreview"
          :remove="removeImage"
          accept="image/*"
        >
          <div>
            <span><a-icon :type="uploading ? 'loading' : 'picture'" /></span>
            <span>上传</span>
          </div>
        </a-upload>
        <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
        </a-modal>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { gridDealEventStatus } from '@/api/gridevent'
import { addImagelibrary, getToken } from '@/api/upload'
import OSS from 'ali-oss'
export default {
  name: 'DealEvent',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      missionStatus: 0,
      checkParams: {
        missionStatusStatus: 'success',
        missionStatusStatusMsg: null
      },
      queryParam: {
        eventId: 0,
        title: '',
        realName: '',
        telephone: '',
        status: 0,
        content: '',
        attachmentList: [],
        imageList: []
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      fileList: [],
      isImageUpload: false,
      uploading: false,
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      }
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.checkParams.missionStatusStatus = 'success'
      this.checkParams.missionStatusStatusMsg = null
      this.queryParam.status = data.status
      this.queryParam.content = ''
      this.queryParam.attachment = []
      this.queryParam.title = data.title
      this.queryParam.telephone = data.telephone
      this.queryParam.realName = data.realName
      this.queryParam.eventId = data.id
      this.getTokenApi()
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    dealStatusApi () {
      const _this = this
      if (_this.queryParam.status <= -1) {
        _this.checkParams.missionStatusStatus = 'error'
        _this.checkParams.missionStatusStatusMsg = '请选择状态'
        return false
      } else {
        _this.checkParams.missionStatusStatus = 'success'
        _this.checkParams.missionStatusStatusMsg = null
      }
      _this.confirmLoading = true
      gridDealEventStatus(_this.queryParam).then((res) => {
        _this.confirmLoading = false
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$emit('ok', 1)
          _this.cancelForm()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    },
    changeStatus (e) {
      console.log(e)
      this.queryParam.status = e
      if (e <= -1) {
        this.checkParams.missionStatusStatus = 'error'
        this.checkParams.missionStatusStatusMsg = '请选择状态'
      } else {
        this.checkParams.missionStatusStatus = 'success'
        this.checkParams.missionStatusStatusMsg = null
      }
    },
    handleCancel (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e.url
      this.previewInfo.previewTitle = e.name
      this.previewInfo.previewVisible = true
      console.log('handlePreview', e)
    },
    removeImage (e) {
      console.log('removeImage', e)
      this.fileList.forEach((item, index) => {
        if (item.uid === e.uid) {
          this.fileList.splice(index, 1)
        }
      })

      var indexa = this.queryParam.imageList.indexOf(e.uid)
      if (indexa > -1) {
        this.queryParam.imageList.splice(indexa, 1)
      }

      var indexb = this.queryParam.attachmentList.indexOf(e.url)
      if (indexb > -1) {
        this.queryParam.attachmentList.splice(indexb, 1)
      }
      return true
    },
    getTokenApi () {
      const _this = this
      _this.fileList = []
      _this.queryParam.imageList = []
      _this.queryParam.attachmentList = []
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    addImagelibraryApi () {
      const _this = this
      addImagelibrary(_this.uploadImageInfo).then((res) => {
        if (res.errorCode === 0) {
          const imgRow = {
            uid: res.result.id,
            name: res.result.fileName,
            status: 'done',
            url: res.result.url
          }
          _this.fileList.push(imgRow)
          _this.queryParam.imageList.push(res.result.id)
          _this.queryParam.attachmentList.push(res.result.url)
          console.log('fileList', _this.fileList)
          _this.$message.success('上传成功')
          console.log('addImagelibrary', res.result)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.isImageUpload = false
        _this.uploading = false
      }).catch((err) => {
        _this.isImageUpload = false
        _this.uploading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    async customRequest (e) {
      console.log('customRequest', e)
      const _this = this
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': _this.uploadImageInfo.fileName,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(_this.uploadImageInfo.fileName, e.file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          _this.uploadImageInfo.url = _this.tokenInfo.domain + result.name
          _this.addImagelibraryApi()
        } else {
          _this.$message.error('上传失败')
          _this.isImageUpload = false
          _this.uploading = false
        }
        console.log(result)
      } catch (e) {
        _this.isImageUpload = false
        _this.uploading = false
        console.log(e)
      }
    },
    beforeUpload (file, fileList) {
      console.log('beforeUpload', file)
      if (this.isImageUpload) {
        this.$message.error('有其他图片正常上传，请稍后在操作')
        return false
      }
      console.log(file)
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      console.log(keyimg)
      this.uploadImageInfo.fileName = keyimg
      this.uploadImageInfo.size = Math.ceil(file.size / 1000)
      this.isImageUpload = true
      this.uploading = true
      return true
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
